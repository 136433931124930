<template>
  <InductiveMiner></InductiveMiner>
</template>

<script>

import InductiveMiner from '@/components/InductiveMiner.vue';

export default {
  name: 'HomeScreen',
  components: {
    InductiveMiner
  }
}
</script>
